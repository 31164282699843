import cn from 'classnames';
import { Link } from 'gatsby';
import React, { useCallback, useMemo } from 'react';

import ArrowSVG from '@/assets/images/home/arrow.inline.svg';
import CircleSVG from '@/assets/images/home/circle.inline.svg';
import TieSVG from '@/assets/images/home/tie.inline.svg';
import TriangleSVG from '@/assets/images/home/triangle.inline.svg';
import { Button, EButtonType } from '@/components/common/Button';
import { courseDetailSectionId, lessonLink } from '@/utils/const';
import { useSize } from '@/utils/hooks';

const totalLessons = [
  {
    title: 'L1',
    desc: 'In Chapter 1, we will journey into the Castle game design. The Castle game design goes beyond mere structures, it creates a dynamic ecosystem within the Sui network. We will start by understanding the core elements of Castle design, and then guide you through connecting to the Sui network.',
    img: '/lesson/lesson1_card_icon.png',
    points: [
      'Castle design and the Move on Sui ecosystem',
      'Connecting to the Sui network',
      'Understanding Sui project structure, package, module',
      'Building, testing, and publishing projects'
    ],
    link: ''
  },
  {
    title: 'L2',
    desc: 'In Chapter 2, we will delve into the fundamental building blocks of Move language, equipping you with the essential knowledge required to construct the framework for your Castle game. This chapter focuses on basic knowledge of the Move language like types, control structures, operations, math, and functions, providing you with a solid foundation to create a thriving Castle game on the Sui network.',
    img: '/lesson/lesson2_card_icon.png',
    points: [
      'Primitive and custom data types and nested data structures',
      'Control structures - conditionals and loops',
      'Developing and managing functions',
      'Handling randomization in the game'
    ],
    link: ''
  },
  {
    title: 'L3',
    desc: `Chapter 3 delves into the core concept of Sui Objects, providing you with an in-depth understanding of how these objects function within the Move on Sui ecosystem. This chapter equips you with the knowledge to master the castle objects' creation, management, and ownership. We explore the basics of defining, creating, storing, and deleting objects, as well as the crucial aspects of object ownership.`,
    img: '/lesson/lesson3_card_icon.png',
    points: [
      'Sui object basics',
      'Object creation, storage, deletion',
      'Object ownership, owned/shared objects'
    ],
    link: ''
  },
  {
    title: 'L4',
    desc: `In Chapter 4, we will focus on completing your Castle project by adding some advanced features in Move on Sui. You'll learn about custom error handling and event emission. In the end, you will publish your Castle project and interact with the game.`,
    img: '/lesson/lesson4_card_icon.png',
    points: [
      'Custom errors',
      'How to emit events',
      'Publishing the game contract'
    ],
    link: ''
  },
  {
    title: 'L5',
    desc: `This chapter focuses on more advanced topics that go beyond the basics and delve into specialized. You'll explore the Capability Design Pattern, One Time Witness, Sponsored Transactions, and Sui Kiosk, gaining deeper insights into the Move on Sui ecosystem.`,
    img: '/lesson/lesson5_card_icon.png',
    points: [
      'Capability Design Pattern',
      'One Time Witness',
      'Sponsored Transactions',
      'Sui Kiosk'
    ],
    link: ''
  }
];

const lessonAmount = totalLessons.length;

const lastLesson = totalLessons[lessonAmount - 1];

export function SectionCourses() {
  const activeIndex = 0;
  const { isMIN, isSM, isMD, isLG } = useSize();
  const showLastLessonAsNormal = useMemo(() => {
    return isMIN || isSM || isMD || isLG;
  }, [isLG, isMD, isMIN, isSM]);
  const lessons = useMemo(
    () =>
      totalLessons.slice(
        0,
        showLastLessonAsNormal ? lessonAmount : lessonAmount - 1
      ),
    [showLastLessonAsNormal]
  );
  const isLastLessonActive = useMemo(
    () => activeIndex === lessonAmount - 1,
    [activeIndex]
  );

  const renderPoint = useCallback(
    (points: string[]) => (
      <div className="min-w-[304px] pl-[18px] md:min-w-[400px] 2xl:max-w-[400px] ">
        <h3 className="text-xl font-medium leading-6 text-textSecondaryColor">
          Knowledge Points
        </h3>
        <ul className="my-2">
          {points.map((point) => (
            <li className="flex items-start gap-x-2" key={point}>
              <div className="mt-2 h-[5px] w-[5px] rounded-full bg-textQuaternaryColor"></div>
              <span className="flex-1 text-base font-medium leading-6 text-[#4B5C68]">
                {point}
              </span>
            </li>
          ))}
        </ul>
        <Link to={lessonLink} target="_blank">
          <Button type={EButtonType.OUTLINE} className="h-[38px] w-40">
            <div className="flex items-center gap-x-[2px]">
              <span className="font-medium">START</span>
              <ArrowSVG className="text-textThemeColor" />
            </div>
          </Button>
        </Link>
      </div>
    ),
    []
  );

  return (
    <section
      className="bg-bgTertiaryColor pb-[84px] pt-[95px] sm:pb-[157px] md:bg-bgSecondaryColor"
      id={courseDetailSectionId}
    >
      <div className="page-layout sm:px-[8.31%]">
        {/* header */}
        <div className="mb-1 flex items-start gap-x-[3px] sm:gap-x-[11px]">
          <TieSVG className="w-[34px] sm:w-[37px]" />
          <h2 className="mt-1 text-xl font-medium leading-[100%] text-textPrimaryColor sm:mt-0 sm:text-[40px]">
            View Course Details
          </h2>
        </div>
        <div className="relative flex items-start pl-[6px]">
          <div
            className={cn(
              'absolute bottom-0 -top-[4px] left-[6px] w-[6px]',
              activeIndex >= 0 ? 'bg-themeColor' : 'bg-[#D1E0EC]'
            )}
          ></div>
          <div className="flex-1 pl-[30px] text-base leading-5 text-textQuaternaryColor sm:pl-[46px] sm:leading-6">
            we will guide you through the process of building the foundation of
            your own castle world
          </div>
        </div>
        {/* content */}
        <div>
          {lessons.map((lesson, index) => {
            const isActive = activeIndex >= index;
            return (
              <div
                key={index}
                className="relative flex flex-col items-start justify-center gap-y-4 gap-x-4 pt-16 pb-3 pl-[12px] md:flex-row md:pl-4 md:pt-[115px] xl:gap-x-[5.47%]"
              >
                <div
                  className={cn(
                    'absolute bottom-0 -top-[4px] left-[6px] w-[6px]',
                    isActive ? 'bg-themeColor' : 'bg-[#D1E0EC]'
                  )}
                ></div>
                <div className="flex min-w-[314px] flex-col gap-y-[13px] pl-[18px] 2xl:max-w-[400px]">
                  <h3 className="relative text-xl font-medium leading-6 text-textSecondaryColor">
                    Introduction
                    <div
                      className={cn(
                        'absolute -left-10 top-[50%] flex h-9 w-9 -translate-y-[50%] items-center justify-center rounded-full text-base font-semibold sm:hidden',
                        isActive
                          ? 'bg-[linear-gradient(97deg,#77F3E6_-16.51%,#4CA2FF_47.53%)] text-white'
                          : 'bg-[#CDDFEE] text-[#052B46]'
                      )}
                    >
                      {lesson.title}
                    </div>
                  </h3>
                  <div className="aspect-[3.27] w-full rounded-[10px] bg-[linear-gradient(180deg,rgba(93,181,255,0.00)_-5.78%,rgba(93,181,255,0.30)_100%)]">
                    <div
                      className="h-full w-full bg-cover bg-center"
                      style={{ backgroundImage: `url(${lesson.img})` }}
                    ></div>
                  </div>
                  <p className="leading-5 text-textQuaternaryColor">
                    {lesson.desc}
                  </p>
                </div>
                {renderPoint(lesson.points)}
              </div>
            );
          })}
        </div>
        {/* last lesson styles in pc */}
        {!showLastLessonAsNormal && (
          <div>
            <div
              className={cn(
                'ml-[6px] h-6 w-[6px]',
                isLastLessonActive ? 'bg-themeColor' : 'bg-[#D1E0EC]'
              )}
            ></div>
            <CircleSVG
              className={cn(
                '-ml-[4px] aspect-square w-[27px]',
                isLastLessonActive ? 'text-themeColor' : 'text-[#D1E0EC]'
              )}
            />
            <div
              className={cn(
                'ml-[6px] h-40 w-[6px]',
                isLastLessonActive ? 'bg-themeColor' : 'bg-[#D1E0EC]'
              )}
            ></div>
            <div className="flex items-start gap-x-5">
              {/* left area */}
              <div className="relative ml-[6px] flex w-[27.2%] items-start">
                <div
                  className={cn(
                    'h-[6px] w-full',
                    isLastLessonActive ? 'bg-themeColor' : 'bg-[#D1E0EC]'
                  )}
                ></div>
                <TriangleSVG
                  className={cn(
                    isLastLessonActive ? 'text-themeColor' : 'text-[#D1E0EC]',
                    '-mt-2'
                  )}
                />
                <div className="absolute left-[50%] -translate-x-[50%]">
                  <Button
                    type={
                      isLastLessonActive
                        ? EButtonType.PRIMARY
                        : EButtonType.GHOST
                    }
                    className={cn(
                      '-mt-[22px] h-[48px] w-[180px] text-[22px] font-semibold leading-[1]',
                      !isLastLessonActive && '!text-[#034F85]'
                    )}
                  >
                    Chapter 5
                  </Button>
                </div>
              </div>
              {/* right area */}
              <div className="flex flex-1 flex-wrap justify-between">
                <div className="-mt-12 w-[45%]">
                  <div className="aspect-[3.27] w-full rounded-[10px] bg-[linear-gradient(180deg,rgba(93,181,255,0.00)_-5.78%,rgba(93,181,255,0.30)_100%)]">
                    <div
                      className="h-full w-full bg-cover bg-center"
                      style={{ backgroundImage: `url(${lastLesson.img})` }}
                    ></div>
                  </div>
                  <h3 className="mt-4 mb-2 text-xl font-medium leading-6 text-textSecondaryColor">
                    Introduction
                  </h3>
                  <p className="leading-5 text-textQuaternaryColor">
                    {lastLesson.desc}
                  </p>
                </div>
                <div className="-mt-12 w-[45%]">
                  {renderPoint(lastLesson.points)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
