import cn from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import DiscordSVG from '@/assets/images/common/discord.inline.svg';
import LogoSVG from '@/assets/images/common/logo.inline.svg';

interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;
  return (
    <header
      className={cn(
        'sticky top-0 z-10 bg-bgPrimaryColor shadow-[0px_-1px_0px_0px_rgba(41,78,109,0.10)_inset]',
        className
      )}
    >
      <div className="page-layout flex h-16 items-center justify-between sm:h-20">
        <Link to="/">
          <LogoSVG className="w-[135px] text-[#142E42] sm:w-auto" />
        </Link>
        <a
          href="https://discord.com/invite/qv8M29pYwX"
          target="_blank"
          rel="noreferrer"
          className="duration-300 hover:opacity-75"
        >
          <DiscordSVG className="h-9 sm:h-11" />
        </a>
      </div>
    </header>
  );
}
