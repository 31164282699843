import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
const data = [
  {
    title: 'What is Move Castle?',
    desc: 'MoveCastle is an educational platform gamifying the learning experience for developers to master the Move programming language on Sui.',
    img: (
      <StaticImage
        src="../../../assets/images/home/edu.png"
        alt="edu"
        objectFit="contain"
      />
    )
  },
  {
    title: 'What is ChainIDE?',
    desc: `ChainIDE, the world's first cloud-based multichain IDE. (ChainIDE Platform Registered User: over 192k, MAU: over 21k) . ChainIDE offers a Move language course integrated into an engaging Castle game, along with ChainIDE Lite for compiling and deploying.`,
    img: (
      <StaticImage
        src="../../../assets/images/home/table.png"
        alt="table"
        objectFit="contain"
      />
    )
  }
];
export function SectionIntro() {
  return (
    <section className="bg-bgTertiaryColor pt-10 pb-12 md:pt-[108px] md:pb-[118px]">
      <div className="page-layout">
        <h2 className="text-center text-xl font-medium leading-[0.6] text-textPrimaryColor sm:text-[40px]">
          Move Castle & ChainIDE
        </h2>
        <p className="leading-1 mt-[14px] mb-6 text-sm text-[#4B5C68] sm:mt-6 sm:mb-14 sm:text-center sm:text-2xl ">
          Cultivating the next-gen developers for Move on Sui.
        </p>
        <ul className="flex flex-col gap-y-3 sm:flex-row sm:gap-x-[4.6%] ">
          {data.map((item) => (
            <li
              key={item.title}
              className="relative w-full rounded-lg bg-[#EAF4FC] px-3 py-4 sm:w-[47.7%] sm:rounded-2xl sm:px-8 sm:pt-12 sm:pb-3"
            >
              <div className="absolute right-[3px] top-1 aspect-[1.56] w-[23.5%] sm:right-[6px] sm:top-2">
                {item.img}
              </div>
              <h3 className="mb-5 font-medium leading-6 text-textSecondaryColor sm:text-xl">
                {item.title}
              </h3>
              <p className="text-sm leading-5 text-textQuaternaryColor sm:text-xl sm:leading-[30px]">
                {item.desc}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
