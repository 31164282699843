/* eslint-disable @typescript-eslint/no-non-null-assertion */
import cn from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import LogoSVG from '@/assets/images/home/logo_long.inline.svg';
import ShareSVG from '@/assets/images/home/share.inline.svg';
import { Button, EButtonType } from '@/components/common/Button';
import { courseDetailSectionId, lessonLink } from '@/utils/const';
interface SectionFirstScreenProps {
  className?: string;
}

export function SectionFirstScreen(props: SectionFirstScreenProps) {
  const { className } = props;

  return (
    <section
      className={cn(
        className,
        'bg-bgTertiaryColor pt-5 pb-[52px] md:bg-bgSecondaryColor'
      )}
    >
      <div className="page-layout flex flex-col-reverse gap-y-5 sm:flex-row sm:items-center sm:justify-between">
        {/* left */}
        <div className="shrink-1 flex w-full min-w-[350px] flex-col gap-y-5 sm:w-[55%] sm:gap-y-10">
          <LogoSVG className="mx-auto w-[70%] text-[#111111] sm:w-[58.7%]" />
          <div className="flex flex-col gap-y-[6px] text-sm leading-normal text-textPrimaryColor sm:gap-y-[10px] sm:text-2xl">
            <h1 className="text-center">Learn Move on Sui in 5 chapters*</h1>
            <p className="text-center">
              The code in this course can be compiled in ChainIDE{' '}
              <a
                href="https://edu.chainide.com/courses/105"
                target="_blank"
                rel="noreferrer"
              >
                <ShareSVG className="mb-[2px] inline-block aspect-square w-[14px] cursor-pointer text-themeColor duration-300 hover:text-themeColorLight sm:mb-1 sm:w-6" />
              </a>
            </p>
          </div>
          <div className="flex items-center justify-center gap-x-2 sm:gap-x-4">
            <Link to={lessonLink} target="_blank">
              <Button
                type={EButtonType.PRIMARY}
                className="h-10 w-[110px] text-xs font-medium sm:h-[60px] sm:w-[186px] sm:text-base"
              >
                START NOW
              </Button>
            </Link>

            <Button
              type={EButtonType.GHOST}
              className="h-10 w-[110px] text-xs font-medium sm:h-[60px] sm:w-[186px] sm:text-base"
              onClick={() => {
                document
                  .getElementById(`${courseDetailSectionId}`)!
                  .scrollIntoView({ behavior: 'smooth' });
              }}
            >
              LEARN MORE
            </Button>
          </div>
        </div>
        {/* right */}
        <div className='relative aspect-square w-full shrink-0 bg-[url("/section_first_bg.png")] bg-cover bg-center sm:w-[42.4%]'>
          <div className='absolute left-[52%] bottom-2 aspect-[0.889] w-[67.6%] -translate-x-[50%] bg-[url("/section_first_cube.png")] bg-cover bg-center'></div>
        </div>
      </div>
    </section>
  );
}
