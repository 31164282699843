import cn from 'classnames';
import React, { MouseEvent, ReactNode, useMemo } from 'react';

import LoadingSvg from '@/assets/images/common/loading.inline.svg';

export enum EButtonType {
  PRIMARY = 'PRIMARY',
  GHOST = 'GHOST',
  OUTLINE = 'OUTLINE'
}
interface IButtonProps {
  className?: string;
  children: ReactNode;
  type?: EButtonType;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonType?: 'submit' | 'reset' | 'button';
}
export function Button(props: IButtonProps) {
  const {
    className,
    children,
    type = EButtonType.PRIMARY,
    loading = false,
    onClick,
    buttonType
  } = props;
  const cls = useMemo(() => {
    const resultArray = [
      'duration-300 rounded-full flex items-center justify-center hover:opacity-75'
    ];
    switch (type) {
      case EButtonType.PRIMARY:
        resultArray.push(
          'bg-[linear-gradient(97deg,#77F3E6_-16.51%,#4CA2FF_47.53%)] text-white'
        );
        break;
      case EButtonType.GHOST:
        resultArray.push('bg-[#DCE8F1] text-[#000]');
        break;
      case EButtonType.OUTLINE:
        resultArray.push(
          'border-[1px] border-solid border-themeColor text-themeColor'
        );
        break;
      default:
        break;
    }
    return resultArray.join(' ');
  }, [type]);

  return (
    <button
      type={buttonType}
      onClick={(e) => {
        if (!loading && onClick) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick(e as any);
        }
      }}
      className={cn(cls, className)}
    >
      {loading ? <LoadingSvg className="animate-spin" width={24} /> : children}
    </button>
  );
}
