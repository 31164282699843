import cn from 'classnames';
import React from 'react';

import LogoSVG from '@/assets/images/common/logo.inline.svg';

interface FooterProps {
  className?: string;
}

const year = new Date().getFullYear();
export function Footer(props: FooterProps) {
  const { className } = props;

  return (
    <footer className={cn('bg-[#02192A] pt-[42px] pb-[22px]', className)}>
      <div className="page-layout">
        <div className="mb-[18px] flex flex-wrap items-center justify-between gap-y-12 sm:mb-12">
          <LogoSVG className="mx-auto w-[135px] text-white sm:mx-0 sm:w-[178px]" />
          <span className="w-full text-center text-lg font-medium leading-[20px] text-[#FFFFFF99] sm:mt-3 sm:w-auto sm:text-right">
            Email:&nbsp;
            <a href="mailto:contact@chainide.com">contact@chainide.com</a>
          </span>
        </div>
        <p className="text-center text-sm text-[#FFFFFF66]">
          ©{year} Copyright ChainIDE Campus. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
